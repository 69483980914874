body {
  margin: 0;
}

a {
  text-decoration: none;
  color: #575555;
  transition: all 0.2s ease;
}

a:hover {
  color: #272525;
}

@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,200;1,400&display=swap');